#nav {
    .nav__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .logo {
            cursor: pointer;
        }
        &__links {
            ul {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                li {
                    &:not(:last-child) {
                        margin-right: 20px;
                        @media (min-width: 1066px) {
                            margin-right: 30px;
                        }
                    }
                    &:hover {
                        transition: var(--main-transition);
                        opacity: 0.8;
                    }
                    a {
                        font-weight: 500;
                        color: var(--dark-blue);
                    }
                }
            }
            &.active {
                display: block;

                ul {
                    position: fixed;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 85vw;
                    padding: 40px 0;
                    top: 100px;
                    left: 50%;
                    transform: translateX(-50%);
                    background-color: var(--white);
                    border: solid 2px var(--very-light-gray);
                    border-radius: 8px;
                    box-shadow: 0px 170px 1000px 60px rgba(0, 0, 0, 0.137);
                    animation: navOpen 0.3s ease-in-out both;
                    li {
                        &:not(:last-child) {
                            margin-right: 0;
                            margin-bottom: 25px;
                        }
                    }
                    a {
                        font-size: 1.0625rem;
                        font-weight: 700;
                    }
                }
            }
        }
        &__burger {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 25px;
            height: 20px;
            cursor: pointer;
            &.burger-active {
                div {
                    background-color: red;
                }
                div:first-child {
                    transform: rotate(40deg);
                }
                div:last-child {
                    transform: rotate(-40deg);
                }
                div:nth-child(2) {
                    transform: translateX(100%);
                    opacity: 0;
                }
            }
            & > div {
                display: block;
                width: 100%;
                height: 4px;
                background-color: var(--dark-blue);
                transition: var(--main-transition);
                transform-origin: left;
                &:not(:last-child) {
                    margin-bottom: 4px;
                }
            }
        }
    }
}
@keyframes navOpen {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
