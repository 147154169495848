#header {
    .header__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        @include breakpoint-up(large) {
            flex-direction: row-reverse;
            justify-content: space-between;
        }
        &__img {
            width: 115%;
            @include breakpoint-up(meduim) {
                width: 100%;
            }
            @include breakpoint-up(large) {
                width: 115%;
                max-width: 1000px;
            }
            img {
                width: 100%;
            }
        }
        &__text {
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            @include breakpoint-up(large) {
                align-items: flex-start;
                text-align: left;
            }
            h1 {
                @include par(2.1875rem, 700, var(--dark-blue));
                line-height: 1.3;
                margin-bottom: 11px;
                @include breakpoint-up(xlarge) {
                    font-size: 3.4rem;
                    line-height: 1.2;
                    margin-bottom: 30px;
                }
            }
            p {
                font-weight: 400;
                color: var(--dark-grayish-blue);
                line-height: 1.7;
                margin-bottom: 23px;
                @include breakpoint-up(large) {
                    font-size: 1.1rem;
                    max-width: 380px;
                    margin-bottom: 30px;
                }
            }
        }
    }
}
